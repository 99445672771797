import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import { allTemplatesId, mainViewId, newViewId } from '../../models/TableView';
import { useTranslation } from 'react-i18next';
import { useTableViewScroll } from '../../contexts/table-view/TableViewScrollContext';
import { ChevronIcon, ChevronType } from '../shared/icon/ChevronIcon';
import DropdownButton from '../shared/form-control/DropdownButton';
import usePermissions from '../../hooks/permissions/usePermissions';
import { Roles } from '../../models/Role';

const TableViewActionButtons = () => {
  const {
    onEditTableView,
    onSaveTableView,
    onUpdateTableView,
    onDiscardTableView,
    selectedTableView,
    isDirty,
    templateModuleSectionId,
    selectedTemplateFormId,
  } = useTableView();
  const { onScrollLeft, onScrollRight, canScrollLeft, canScrollRight } = useTableViewScroll();
  const { t } = useTranslation(['table-view']);
  const hasPermission = usePermissions();
  const isTeamLead = hasPermission(Roles.TeamLead);

  const buttonClasses = 'flex items-center justify-center h-[30px] w-9 rounded-md bg-gray-6 text-gray-2 p-1 cursor-pointer hover:bg-gray-5';

  const saveOptions = [
    { id: 'save', text: t('buttons.save-current-view'), value: 'save' },
    { id: 'save-as-new', text: t('buttons.save-as-new'), value: 'save-as-new' },
  ];
  const saveActions = {
    ['save' as string]: onUpdateTableView,
    ['save-as-new' as string]: () => onSaveTableView(true),
  };
  return (
    <div className="flex items-center gap-2">
      {selectedTableView?.id === mainViewId && selectedTemplateFormId !== allTemplatesId && templateModuleSectionId && isDirty && (
        <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={() => onSaveTableView(true)}>
          {t('buttons.save-as-new')}
        </Button>
      )}
      {selectedTableView?.id !== mainViewId && templateModuleSectionId && (
        <>
          {(isTeamLead || selectedTableView.userId) && (
            <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={onEditTableView}>
              {t('buttons.edit')}
            </Button>
          )}
          {isDirty && (
            <>
              <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={onDiscardTableView}>
                {t('buttons.discard')}
              </Button>
              {(!isTeamLead || selectedTableView.id === newViewId) &&
                (isTeamLead || selectedTableView.userId ? (
                  <Button
                    size={ButtonSize.S}
                    type={ButtonType.SECONDARY}
                    onClick={() => (selectedTableView.id === newViewId ? onSaveTableView(false) : onUpdateTableView())}
                  >
                    {t('buttons.save')}
                  </Button>
                ) : (
                  <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={() => onSaveTableView(!isTeamLead)}>
                    {t('buttons.save-as-new')}
                  </Button>
                ))}
              {isTeamLead && selectedTableView.id !== newViewId && (
                <DropdownButton
                  onSelect={(o) => {
                    saveActions[o.id as string]();
                  }}
                  className="flex-shrink-0"
                  dropdownWidth="!min-w-[180px]"
                  options={saveOptions}
                  ignoreMinWidth
                  size={ButtonSize.S}
                  type={ButtonType.SECONDARY}
                >
                  {t('buttons.save')}
                </DropdownButton>
              )}
            </>
          )}
        </>
      )}
      <ChevronIcon
        type={ChevronType.LEFT}
        className={`${buttonClasses} ${!canScrollLeft ? 'pointer-events-none opacity-50' : ''}`}
        onClick={onScrollLeft}
      />
      <ChevronIcon
        type={ChevronType.RIGHT}
        className={`${buttonClasses} ${!canScrollRight ? 'pointer-events-none opacity-50' : ''}`}
        onClick={onScrollRight}
      />
    </div>
  );
};

export default TableViewActionButtons;
