import EmptyDataGridsvg from '../../assets/images/empty-grid.svg';
import Button, { ButtonSize } from '../shared/form-control/Button';
import { Heading, HeadingSize } from '../shared/text/Heading';
import EditIcon from '../shared/icon/EditIcon';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import { useTranslation } from 'react-i18next';
const EmptyView = () => {
  const { onEditTableView } = useTableView();
  const { t } = useTranslation(['table-view']);
  return (
    <div className="mt-40 flex flex-col items-center gap-6 text-center">
      <img src={EmptyDataGridsvg} alt={t('empty.title')} className="max-w-[125px]" />
      <div>
        <Heading size={HeadingSize.H3} textColor="text-black" className="my-2">
          {t('empty.title')}
        </Heading>
        <div className="text-gray-2"> {t('empty.description')}</div>
      </div>
      <Button size={ButtonSize.M} onClick={onEditTableView}>
        <Button.Slot name="Icon">
          <EditIcon className="h-5 w-5" />
        </Button.Slot>
        {t('empty.edit-button')}
      </Button>
    </div>
  );
};

export default EmptyView;
