import { useTableViewFilters } from '../../contexts/table-view/TableViewFilterContext';
import { FC, ReactElement, createElement, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnType, ColumnConfig } from '../../models/TableView';
import ContextMenuIcon from '../shared/icon/ContextMenuIcon';
import { dataAttributeProps, mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import Popover, { PopoverHandle } from '../shared/popover/Popover';
import { useTranslation } from 'react-i18next';
import FunnelIcon from '../shared/icon/FunnelIcon';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import { FilterRenderers, FilterRendersKeys } from './filter-renderers/FilterRenderers';
import SkeletonLoader from '../shared/skeleton-loader/SkeletonLoader';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import XIcon from '../shared/icon/XIcon';

type FilterMenuProps = {
  selectedTemplateId: string;
  columnConfig: ColumnConfig;
  onToggle?: (value: boolean) => void;
  triggerIcon?: ReactElement;
};

const NoFilterRenderer: FC = () => {
  const { t } = useTranslation(['table-view']);
  return <div>{t('filter-menu.no-filters')}</div>;
};

const TableViewColumnFilterMenu: FC<FilterMenuProps> = (props) => {
  const { selectedTemplateId, columnConfig, onToggle, triggerIcon } = props;
  const { t } = useTranslation(['table-view', 'common']);
  const { filters, clearFilter, setFilters, getInternalChangeRef, actionTypes } = useTableViewFilters();
  const { selectedTableView, onApplyFilter } = useTableView();
  const popoverRef = useRef<PopoverHandle>(null);

  const internalChangeRef = getInternalChangeRef(columnConfig, selectedTemplateId);

  const internalFilter = useMemo(
    () => filters?.[selectedTemplateId]?.[columnConfig.value]?.filter,
    [filters, selectedTemplateId, columnConfig.value],
  );

  const appliedFilter = useMemo(() => {
    return selectedTableView?.columnConfigurations?.[selectedTemplateId]?.columns.find((col) => col.value === columnConfig.value)?.filter;
  }, [columnConfig.value, selectedTableView?.columnConfigurations, selectedTemplateId]);

  const renderer = useMemo(() => {
    if (columnConfig.type === ColumnType.MetaData) {
      return FilterRenderers[columnConfig.value as FilterRendersKeys]?.render;
    } else {
      return FilterRenderers[actionTypes[columnConfig.value]?.actionType as FilterRendersKeys]?.render;
    }
  }, [actionTypes, columnConfig.type, columnConfig.value]);

  const applyFilter = useCallback(() => {
    onApplyFilter(columnConfig, internalFilter);
    popoverRef.current?.closePopover();
    internalChangeRef.current = true;
  }, [onApplyFilter, columnConfig, internalFilter, internalChangeRef]);

  const clearFilterInternal = useCallback(() => {
    clearFilter(columnConfig, selectedTemplateId);
  }, [clearFilter, columnConfig, selectedTemplateId]);

  const menuWasOpen = useRef(true); // true by default to apply filter on first render
  const unmountAction = useRef<(() => void) | null>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const onToggleInternal = useCallback(
    (value: boolean) => {
      setMenuIsOpen(value);
      onToggle?.(value);
    },
    [onToggle],
  );
  useEffect(() => {
    if (menuIsOpen) {
      menuWasOpen.current = true;
    }

    if (menuWasOpen.current && !menuIsOpen) {
      menuWasOpen.current = false;

      setFilters(columnConfig, selectedTemplateId, appliedFilter);
    }

    unmountAction.current = () => setFilters(columnConfig, selectedTemplateId, appliedFilter);
  }, [appliedFilter, columnConfig, internalChangeRef, menuIsOpen, selectedTemplateId, setFilters]);

  useEffect(() => {
    return () => {
      unmountAction.current?.();
    };
  }, []);

  return (
    <Popover
      ref={popoverRef}
      onToggle={onToggleInternal}
      content={
        <div className="flex h-full min-w-64 flex-col justify-between bg-white">
          <div className="bg-gray-6 -mx-4 -mt-4 flex items-center justify-between gap-2 rounded-md rounded-t p-2">
            <div className="flex items-center gap-2">
              <FunnelIcon className="h-5 w-5" />
              <span>{t('filter-menu.title')}</span>
            </div>
            <div>
              <XIcon
                className="h-4 w-4"
                onClick={() => {
                  onToggleInternal(false);
                  popoverRef.current?.closePopover();
                }}
              />
            </div>
          </div>

          <div className="min-h-20 flex-grow p-2">
            <SkeletonLoader ready type="rowDetail" rows={3}>
              {createElement(renderer ?? memo(NoFilterRenderer), { columnConfig, selectedTemplateId })}
            </SkeletonLoader>
          </div>

          <div className="-mx-4 -mb-2 mt-4 flex items-center justify-end gap-2 px-2">
            <Button type={ButtonType.SECONDARY} size={ButtonSize.S} onClick={clearFilterInternal} disabled={!internalFilter}>
              {t('filter-menu.clear')}
            </Button>
            <Button
              type={ButtonType.PRIMARY}
              size={ButtonSize.S}
              onClick={() => applyFilter()}
              disabled={internalFilter === appliedFilter || (!appliedFilter && !internalFilter)}
            >
              {t('filter-menu.apply')}
            </Button>
          </div>
        </div>
      }
      placement="bottom"
    >
      {(popover, toggle, isOpen) => (
        <div {...popover} className="flex items-center">
          <div
            aria-haspopup="true"
            aria-controls={`context-menu-${columnConfig.value}`}
            aria-expanded={isOpen}
            aria-label={t('common:aria-label.context-menu')}
            data-cy="context-menu"
            {...dataAttributeProps(props)}
            className={`${triggerIcon ? 'cursor-pointer select-none' : 'hover:border-primary-2 hover:bg-primary-2 relative cursor-pointer select-none rounded-md border border-transparent px-1 hover:bg-opacity-25'}`}
            {...mouseAndKeyboardCallbackProps(toggle)}
          >
            {triggerIcon ? triggerIcon : <ContextMenuIcon className="h-4 w-4 rotate-90" />}
          </div>
        </div>
      )}
    </Popover>
  );
};

export default memo(TableViewColumnFilterMenu);
