import { ApiResponse } from '../models/ApiResponse';
import {
  CreateTableViewDefaultRequest,
  TableViewCreateRequest,
  TableViewDefaultResponse,
  TableViewResponse,
  TableViewUpdateRequest,
} from './../models/TableView';
import BaseService from './BaseService';

class TableViewService extends BaseService {
  public static getTableViews(clientId: string, clientModuleSectionId: string): Promise<ApiResponse<TableViewResponse[]>> {
    return this.get(`/v1/table-view`, { params: { clientId, clientModuleSectionId } });
  }

  public static createTableView(tableViewRequest: TableViewCreateRequest): Promise<ApiResponse<TableViewResponse>> {
    return this.post(`/v1/table-view`, tableViewRequest);
  }

  public static updateTableView(id: string, TableViewUpdateRequest: TableViewUpdateRequest): Promise<ApiResponse<TableViewResponse>> {
    return this.put(`/v1/table-view/${id}`, TableViewUpdateRequest);
  }

  public static deleteTableView(id: string): Promise<ApiResponse<void>> {
    return this.delete(`/v1/table-view/${id}`);
  }

  public static setAsDefaultView(tableViewDefaultRequest: CreateTableViewDefaultRequest): Promise<ApiResponse<TableViewDefaultResponse>> {
    return this.post(`/v1/table-view/defaults`, tableViewDefaultRequest);
  }

  public static deleteAsDefaultView(tableViewDefaultId: string): Promise<ApiResponse<boolean>> {
    return this.delete(`/v1/table-view/defaults/${tableViewDefaultId}`);
  }

  public static setAsFavouriteView(tableViewId: string): Promise<ApiResponse<{ tableViewFavouriteId: string }>> {
    return this.post(`/v1/table-view/favourites`, { tableViewId });
  }

  public static deleteAsFavouriteView(tableViewId: string): Promise<ApiResponse<boolean>> {
    return this.delete(`/v1/table-view/favourites/${tableViewId}`);
  }
}

export default TableViewService;
