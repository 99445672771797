import { ComponentRef, RefObject, createContext, useContext } from 'react';
import { ColumnConfig, SortDirection, TableView } from '../../models/TableView';
import { FilterValue } from '../../models/TableViewFilters';
import { SelectItemContextProvider } from '../select-items/SelectItemsContext';
import { ClientModule } from '../../models/ClientModule';

export type ResultCounts = { count: number; total: number };

export type RenderedGQL = {
  query: string;
  variables: Record<string, unknown>;
};

export type TableViewContextType = {
  templateModuleSectionId: string | null;
  clientModuleSectionId: string | null | undefined;
  clientModuleSectionName: string;
  clientModuleId: string;
  availableViews: TableView[];
  setAvailableViews?: (value: TableView[] | ((prev: TableView[]) => TableView[])) => void;
  fetchTableViews: () => void;
  selectedOriginalTableView: TableView;
  setSelectedOriginalTableView?: (value: TableView | ((prev: TableView) => TableView)) => void;
  selectedTableView: TableView;
  setSelectedTableView?: (value: TableView | ((prev: TableView) => TableView)) => void;
  selectedTemplateFormId: string | null;
  setSelectedTemplateFormId: (value: string | null | ((prev: string | null) => string | null)) => void;
  isDirty: boolean;
  onEditTableView: () => void;
  onDiscardTableView: () => void;
  onSaveTableView: (asClone?: boolean) => Promise<void>;
  onUpdateTableView: () => Promise<void>;
  resultCounts: ResultCounts;
  setResultCounts: (value: ResultCounts | ((prev: ResultCounts) => ResultCounts)) => void;
  onApplyFilter: (columnConfig: ColumnConfig, filter: FilterValue | undefined) => void;
  onResetFilters: () => void;
  onApplySorting: (columnConfig: ColumnConfig, sortDirection: SortDirection | undefined) => void;
  onResetSorting: () => void;
  exportProvider: RefObject<ComponentRef<typeof SelectItemContextProvider>>;
  clientModule?: ClientModule | null;
  renderedGQL: RenderedGQL | null;
  setRenderedGQL: (value: RenderedGQL) => void;
};

export const TableViewContext = createContext<TableViewContextType>(null!);
export const useTableView = (): TableViewContextType => useContext(TableViewContext);
