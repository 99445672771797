import { createElement, FC, useMemo, useState } from 'react';
import { FormAction, FormSection } from '../../models/Form';
import ObjectUtils from '../../utils/ObjectUtils';
import ActionTypes from '../form/ActionTypes';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormActionContext } from '../../contexts/FormActionContext';
import { TextinputActionData } from '../form/action-types/text-input/TextInputAction';
import { DefaultData } from '../form/action-types/ActionBaseProps';
import Checkbox from '../shared/form-control/Checkbox';
import DragHandleIcon from '../shared/icon/DragHandleIcon';
import { useDraggable } from '@dnd-kit/core';
import { TableViewConfigDroppableTypes } from './TableViewConfigModal';
import { v4 as uuid } from 'uuid';
import { ColumnConfig, ColumnType } from '../../models/TableView';

type Props = {
  action: FormAction;
  section: FormSection;
  selected: boolean;
  onChange: (action: FormAction) => void;
};

const NOOP = () => Promise.resolve(null);

const SelectableActionRenderer: FC<Props> = (props) => {
  const { action, section, onChange, selected } = props;
  const [id] = useState(uuid);
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: id,
    data: { type: TableViewConfigDroppableTypes.Actions, item: { value: action.id, type: ColumnType.Action } as ColumnConfig },
  });

  const data = useMemo(() => {
    const translatedData = LanguageUtils.getActionDataTranslation(ObjectUtils.DeepClone(action));

    return {
      ...translatedData,
      question: translatedData?.previewQuestion || translatedData?.question || translatedData?.title,
      buttonText: translatedData.buttonText || '',
    };
  }, [action]);

  const component = useMemo(() => {
    if (!action.type) {
      return;
    }

    if (action.type === 'TextInputAction') {
      (action.data as TextinputActionData & DefaultData).requiresPrefilledText = false;
    }

    return ActionTypes[action.type].editAction;
  }, [action.data, action.type]);

  return (
    <>
      <FormActionContext.Provider
        key={action.id}
        value={{
          currentSection: section,
          currentAction: action,
          actionValid: false,
          riskLevel: 0,
          onAnswer: () => Promise.resolve(),
          onValid: NOOP,
          readOnly: true,
          createPlaceholder: NOOP,
          deletePlaceholder: NOOP,
          busySavingAction: false,
        }}
      >
        <div className={`${isDragging ? 'border-primary-2 bg-primary-2 bg-opacity-10' : ''} group flex w-11/12 items-start gap-1 p-2`}>
          <div
            key={action.id}
            ref={setNodeRef}
            {...listeners}
            {...attributes}
            style={{
              opacity: isDragging || selected ? 0.5 : 1,
              pointerEvents: isDragging || selected ? 'none' : 'auto',
            }}
          >
            <DragHandleIcon className={`mr-1 mt-[1px] h-5 w-5 flex-shrink-0 cursor-grab opacity-0 ${!selected && 'group-hover:opacity-100'}`} />
          </div>
          <div className="-mt-2 mr-1">
            <Checkbox value={selected} onChange={() => onChange(action)} />
          </div>
          <div className="flex-grow">
            {component &&
              createElement(component, {
                id: action.id,
                data,
                response: null!,
                required: false,
              })}
          </div>
        </div>
      </FormActionContext.Provider>
    </>
  );
};
export default SelectableActionRenderer;
