import { createContext, useContext } from 'react';
import { TableView } from '../../models/TableView';

export type TableViewEditContextType = {
  selectedTableView: TableView | null;
  setSelectedTableView?: (value: TableView | null | ((prev: TableView | null) => TableView | null)) => void;
};

export const TableViewEditContext = createContext<TableViewEditContextType>(null!);
export const useTableViewEdit = (): TableViewEditContextType => useContext(TableViewEditContext);
