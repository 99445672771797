import { FC, useState } from 'react';
import Checkbox from '../shared/form-control/Checkbox';
import DragHandleIcon from '../shared/icon/DragHandleIcon';
import { useDraggable } from '@dnd-kit/core';
import { TableViewConfigDroppableTypes } from './TableViewConfigModal';
import { v4 as uuid } from 'uuid';
import { ColumnConfig, ColumnType, TableViewMetaDataKey } from '../../models/TableView';

export type TableViewMetaData = {
  metaDataFieldName: TableViewMetaDataKey;
  label: string;
};

type Props = {
  metaData: TableViewMetaData;
  selected: boolean;
  onChange: (metaDataFieldName: TableViewMetaDataKey) => void;
};

const SelectableMetaData: FC<Props> = (props) => {
  const { onChange, selected, metaData } = props;

  // Generate a persisted UUID
  const [id] = useState(uuid);
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: id,
    data: { type: TableViewConfigDroppableTypes.Meta, item: { value: metaData.metaDataFieldName, type: ColumnType.MetaData } as ColumnConfig },
  });

  return (
    <div className={`${isDragging ? 'border-primary-2 bg-primary-2 bg-opacity-10' : ''} group flex w-11/12 items-start gap-1 p-2`}>
      <div
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        style={{
          opacity: isDragging || selected ? 0.5 : 1,
          pointerEvents: isDragging || selected ? 'none' : 'auto',
        }}
      >
        <DragHandleIcon className={`mr-1 mt-[1px] h-5 w-5 flex-shrink-0 cursor-grab opacity-0 ${!selected && 'group-hover:opacity-100'}`} />
      </div>
      <div className="-mt-2 mr-1">
        <Checkbox value={selected} onChange={() => onChange(metaData.metaDataFieldName)} />
      </div>
      <div className="flex-grow">{metaData.label}</div>
    </div>
  );
};

export default SelectableMetaData;
