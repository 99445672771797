import { createContext, RefObject, useCallback, useContext, useEffect, useState } from 'react';
import { FCWithChildren } from '../../types/FCWithChildren';

type TableViewScrollContextProps = {
  onScrollLeft: () => void;
  onScrollRight: () => void;
  canScrollLeft: boolean;
  canScrollRight: boolean;
};

const TableViewScrollContext = createContext<TableViewScrollContextProps>(null!);

export const useTableViewScroll = () => useContext(TableViewScrollContext);

type TableViewScrollProviderProps = {
  columnWidths: number[];
  scrollContainerRef: RefObject<HTMLDivElement>;
};

export const TableViewScrollProvider: FCWithChildren<TableViewScrollProviderProps> = (props) => {
  const { children, scrollContainerRef } = props;
  const FIXED_SCROLL_AMOUNT = 200;
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' });
    }
  }, [scrollContainerRef]);

  const onScrollLeft = () => {
    if (scrollContainerRef.current) {
      const newScrollLeft = Math.max(scrollContainerRef.current.scrollLeft - FIXED_SCROLL_AMOUNT, 0);
      scrollContainerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const onScrollRight = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = scrollContainerRef.current;
      const newScrollLeft = Math.min(scrollLeft + FIXED_SCROLL_AMOUNT, scrollWidth - clientWidth);
      scrollContainerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const updateScrollLimits = useCallback(() => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    }
  }, [scrollContainerRef]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const scrollElement = scrollContainerRef.current;
      scrollElement.addEventListener('scroll', updateScrollLimits);
      updateScrollLimits();
      return () => {
        scrollElement.removeEventListener('scroll', updateScrollLimits);
      };
    }
  }, [scrollContainerRef, updateScrollLimits]);

  return (
    <TableViewScrollContext.Provider value={{ onScrollLeft, onScrollRight, canScrollLeft, canScrollRight }}>
      {children}
    </TableViewScrollContext.Provider>
  );
};
