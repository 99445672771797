import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useImportFilter = () => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const hasImportFilter = useMemo(() => searchParams.has('importId'), [searchParams]);

  return {
    searchParams,
    hasImportFilter,
    importId: searchParams.get('importId'),
  };
};
