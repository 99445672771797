import { FC } from 'react';
import DragHandleIcon from '../shared/icon/DragHandleIcon';
import Tooltip from '../shared/Tooltip';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ColumnConfig, ColumnType } from '../../models/TableView';
import { TableViewConfigDroppableTypes } from './TableViewConfigModal';
import Badge from '../shared/badge/Badge';
import { interpolateActionData } from '../../utils/interpolation/ActionDataInterpolator';
import { ActionPlaceholderData } from '../../models/Form';
import XIcon from '../shared/icon/XIcon';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';

type Props = {
  config: ColumnConfig;
  title: string;
  placeholders: Record<string, ActionPlaceholderData>;
  onRemove: () => void;
};

const SortableColumnConfig: FC<Props> = (props) => {
  const { config, title, placeholders, onRemove } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: config.value,
    data: { type: TableViewConfigDroppableTypes.Columns, item: config },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      key={`${config.value}`}
      style={style}
      className="text-dpm-14 group my-2 flex items-center justify-between rounded-md bg-white p-2 font-medium"
    >
      <div className="flex cursor-grabbing items-center truncate" {...listeners} {...attributes}>
        <DragHandleIcon className="mr-1 hidden h-4 w-4 flex-shrink-0 group-hover:block" />
        <Tooltip text={title} truncatedTextMode>
          {(tooltip) => (
            <div {...tooltip} className="min-w-0 max-w-full truncate">
              {config.type === ColumnType.MetaData && <Badge text="Meta" textClass="text-gray-2" textSize="text-dpm-12" className="mr-2" />}
              {interpolateActionData(title, placeholders)}
            </div>
          )}
        </Tooltip>
      </div>

      <div
        className="ml-2 h-4 w-4 cursor-pointer opacity-0 transition-opacity duration-200 group-hover:opacity-100"
        {...mouseAndKeyboardCallbackProps(onRemove)}
      >
        <XIcon className="text-gray-2 h-4 w-4" />
      </div>
    </div>
  );
};

export default SortableColumnConfig;
