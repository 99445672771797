import { useSortable } from '@dnd-kit/sortable';
import { TableViewConfigDroppableTypes } from './TableViewConfigModal';
import { CSS } from '@dnd-kit/utilities';
import { FormConfig } from '../../models/Form';
import { FCWithChildren } from '../../types/FCWithChildren';
import DragHandleIcon from '../shared/icon/DragHandleIcon';
import Tooltip from '../shared/Tooltip';
import LanguageUtils from '../../utils/LanguageUtils';

type Props = {
  template: FormConfig;
};

const SortableTemplate: FCWithChildren<Props> = (props) => {
  const { template } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: template.id,
    data: { type: TableViewConfigDroppableTypes.Templates, item: template as FormConfig },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const templateTitle = LanguageUtils.getTranslation('title', template.translations || {});

  return (
    <div key={template.id} style={style} ref={setNodeRef} className="flex min-w-0 flex-1 items-center">
      <span {...listeners} {...attributes}>
        <DragHandleIcon className="mr-1 h-4 w-4 flex-shrink-0 opacity-0 group-hover:opacity-100" />
      </span>
      <Tooltip text={templateTitle} truncatedTextMode>
        {(tooltip) => (
          <div {...tooltip} className="min-w-0 max-w-full truncate">
            {templateTitle}
          </div>
        )}
      </Tooltip>
    </div>
  );
};
export default SortableTemplate;
