import { useTranslation } from 'react-i18next';
import Button, { ButtonType, ButtonSize } from '../shared/form-control/Button';
import NoSortingSvg from '../../assets/images/empty-sorting.svg';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import { useCallback, useMemo } from 'react';
import { ColumnConfig, ColumnType, SortDirection, SortDirectionKeys, TableViewMetaDataKey, mainViewId } from '../../models/TableView';
import XIcon from '../shared/icon/XIcon';
import { useTableViewFilters } from '../../contexts/table-view/TableViewFilterContext';
import Badge from '../shared/badge/Badge';
import { ArrowIcon, ArrowType } from '../shared/icon/ArrowIcon';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';

const TableViewSortingOverview = () => {
  const { t } = useTranslation(['table-view']);
  const { selectedTableView, selectedOriginalTableView, onResetSorting, onApplySorting, selectedTemplateFormId } = useTableView();
  const { actionTypes } = useTableViewFilters();

  const columns = useMemo(() => {
    if (!selectedTemplateFormId || !selectedTableView?.columnConfigurations) return [];
    const columnConfigKey = selectedTableView?.id === mainViewId ? mainViewId : selectedTemplateFormId;
    const selectedConfig = selectedTableView.columnConfigurations[columnConfigKey];
    return (selectedConfig?.columns ?? []).filter((col) => col.sortDirection !== null && col.sortDirection !== undefined);
  }, [selectedTableView?.id, selectedTableView.columnConfigurations, selectedTemplateFormId]);

  const originalColumns = useMemo(() => {
    if (!selectedTemplateFormId || !selectedOriginalTableView?.columnConfigurations) return [];
    const configKey = selectedOriginalTableView.id === mainViewId ? mainViewId : selectedTemplateFormId;
    const selectedConfig = selectedOriginalTableView.columnConfigurations[configKey];
    return (selectedConfig?.columns ?? []).filter((col) => col.sortDirection !== null && col.sortDirection !== undefined);
  }, [selectedTemplateFormId, selectedOriginalTableView.columnConfigurations, selectedOriginalTableView.id]);

  const removeSorting = useCallback(
    (config: ColumnConfig) => {
      if (!selectedTemplateFormId) return;
      onApplySorting(config, undefined);
    },
    [onApplySorting, selectedTemplateFormId],
  );

  const resetToDefaults = useCallback(() => {
    onResetSorting();
  }, [onResetSorting]);

  const canResetToDefaults = useMemo(() => {
    if (columns.length !== originalColumns.length) return true;

    return columns.some((column) => {
      const originalColumn = originalColumns.find((origCol) => origCol.value === column.value);
      return !originalColumn || originalColumn.filter !== column.filter;
    });
  }, [columns, originalColumns]);

  const sortColumn = useCallback(
    (config: ColumnConfig) => {
      const sortDirection = config.sortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
      onApplySorting(config, sortDirection);
    },
    [onApplySorting],
  );

  return (
    <div className="min-w-128 max-w-[800px] px-2">
      <div className="flex items-center justify-between">
        <h3 className="text-dpm-18 font-semibold">{t('table-view:global-actions.sort-popover.title')}</h3>
        <Button type={ButtonType.TERTIARY} size={ButtonSize.S} onClick={resetToDefaults} disabled={!canResetToDefaults}>
          {t('table-view:global-actions.sort-popover.reset')}
        </Button>
      </div>

      {columns.length === 0 ? (
        <div className="text-center">
          <img src={NoSortingSvg} alt={t('table-view:global-actions.sort-popover.empty')} className="mx-auto p-8" />
          <p className="text-gray-2 pt-4">{t('table-view:global-actions.sort-popover.empty')}</p>
        </div>
      ) : (
        <div className="mt-4 max-h-[450px] overflow-y-auto pr-2">
          {columns.map((config, index) => (
            <div key={index} className="mb-4">
              <div className="flex items-center justify-between">
                <div className="text-dpm-14 mb-1 font-medium">
                  {config.type === ColumnType.MetaData ? t(`meta.${config.value as TableViewMetaDataKey}`) : actionTypes[config.value].title}
                </div>
                <div className="ml-4 flex items-center justify-end gap-2 pb-1">
                  <XIcon className="h-5 w-5 flex-shrink-0" onClick={() => removeSorting(config)} />
                </div>
              </div>
              <Badge
                textClass="text-dpm-gray-2 !font-normal"
                text={
                  <div className="flex cursor-pointer items-center gap-2" {...mouseAndKeyboardCallbackProps(() => sortColumn(config))}>
                    <ArrowIcon className="h-4 w-4" type={config.sortDirection === SortDirection.Ascending ? ArrowType.UP : ArrowType.DOWN} />
                    {t(SortDirectionKeys[config.sortDirection as SortDirection])}
                  </div>
                }
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TableViewSortingOverview;
