import { FC, useCallback, useMemo, useState } from 'react';
import { ColumnConfig, ColumnType, tableViewMetadata, TableViewMetaDataKey } from '../../models/TableView';
import { InputStyle } from '../shared/form-control/Input';
import { SearchInput } from '../shared/form-control/SearchInput';
import SelectableMetaData from './SelectableMetaData';
import { useTranslation } from 'react-i18next';
import { FormConfig } from '../../models/Form';
import { useTableViewEdit } from '../../contexts/table-view/TableViewEditContext';

const SelectableMetaDataRenderer: FC<{ form: FormConfig }> = (props) => {
  const { form } = props;
  const { selectedTableView, setSelectedTableView } = useTableViewEdit();
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useTranslation(['table-view', 'common']);

  const columnConfiguration = useMemo(
    () => (form ? selectedTableView?.columnConfigurations?.[form.id] || { columns: [] } : { columns: [] }),
    [selectedTableView?.columnConfigurations, form],
  );

  const updateTableView = useCallback(
    (updatedColumns: ColumnConfig[]) => {
      if (setSelectedTableView) {
        setSelectedTableView((prev) => {
          if (!prev) return prev;

          return {
            ...prev,
            columnConfigurations: {
              ...prev.columnConfigurations,
              [form.id]: {
                ...prev.columnConfigurations?.[form.id],
                columns: updatedColumns,
                enabled: prev.columnConfigurations?.[form.id]?.enabled ?? true,
                enableIndexNumbering: prev.columnConfigurations?.[form.id]?.enableIndexNumbering ?? false,
              },
            },
          };
        });
      }
    },
    [form.id, setSelectedTableView],
  );

  const handleMetadataSelect = (key: TableViewMetaDataKey) => {
    const isSelected = columnConfiguration.columns.some((col) => col.value === key);
    const updatedColumns = isSelected
      ? columnConfiguration.columns.filter((col) => col.value !== key)
      : [...columnConfiguration.columns, { value: key, type: ColumnType.MetaData }];

    updateTableView(updatedColumns);
  };

  const filteredMetadata = useMemo(() => {
    return Object.values(tableViewMetadata)
      .map((metaData) => ({ metaDataFieldName: metaData, label: t(`meta.${metaData}`) }))
      .filter((metaData) => {
        const query = searchQuery.toLowerCase();
        return metaData.label.toLowerCase().includes(query) && metaData.metaDataFieldName !== tableViewMetadata.subTitle;
      });
  }, [searchQuery, t]);

  return (
    <>
      <SearchInput
        placeholder={t('common:list.filter.search')}
        style={InputStyle.MINIMAL}
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        value={searchQuery}
      />
      <div className="mt-3 h-[calc(65vh_-_130px)] overflow-y-auto">
        <ul>
          {filteredMetadata.map((metaData) => (
            <li key={metaData.metaDataFieldName}>
              <SelectableMetaData
                metaData={metaData}
                onChange={() => handleMetadataSelect(metaData.metaDataFieldName)}
                selected={columnConfiguration.columns.some((col) => col.value === metaData.metaDataFieldName)}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SelectableMetaDataRenderer;
