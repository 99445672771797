import { FC, memo } from 'react';
import StringUtils from '../../../utils/StringUtils';
import { useTableViewFilters } from '../../../contexts/table-view/TableViewFilterContext';

const DefaultTextRenderer: FC<{ data: string }> = (props) => {
  const { searchTerm } = useTableViewFilters();

  return searchTerm && `${props.data ?? ''}`.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ? (
    <>{StringUtils.highlightText(props.data, searchTerm)}</>
  ) : (
    <>{props.data}</>
  );
};

export default memo(DefaultTextRenderer);
