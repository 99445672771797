import { Trans, useTranslation } from 'react-i18next';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import { FCWithChildren } from '../../types/FCWithChildren';
import TableViewActionButtons from './TableViewActionButtons';
import TableViewGlobalActions from './TableViewGlobalActions';

const TableViewTopContainer: FCWithChildren = (props) => {
  const { children } = props;
  const { clientModuleSectionName, resultCounts } = useTableView();
  const { t } = useTranslation(['table-view', 'module']);
  return (
    <>
      <div className="flex items-center justify-between px-4">
        <div>
          <Trans
            t={t}
            i18nKey="table-view:data-table.result-count"
            components={{ Bold: <span className="font-medium" /> }}
            values={{
              section: clientModuleSectionName || t('module:unnamed-section'),
              count: resultCounts.count || 0,
              total: resultCounts.total || 0,
            }}
          />
        </div>
        <div>{children}</div>
      </div>
      <div className="flex items-center justify-between py-4 pr-4">
        <TableViewGlobalActions />

        <TableViewActionButtons />
      </div>
    </>
  );
};

export default TableViewTopContainer;
